import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import EventActions from '../containers/event_actions'
import UserInitials from './user_initials'
import eventTime from '../helpers/event-time'

import EventTypes, { WORK_DATE, GENERAL_EVENT, APPOINTMENT, GROUPED_AUTOMATION_TASKS } from './event_types'

const generateTitle = title => {
  if (title === '') { return '' }
  return ` ${String.fromCharCode(183)} ${title}`
}

const Event = ({
  id,
  type,
  title,
  start,
  end,
  url,
  location,
  locationUrl,
  selectedColor,
  defaultColor,
  users,
  currentDay,
  onDelete,
  onColorChange,
  canManageEvent,
  contact,
  job,
  jobId,
  canViewContact,
  eventColors
}) => (
  <li className='c-card'>

    <div className='flex-grid c-event'>
      <div className='flex-grid__cell u-mrgn-l--x0 u-mrgn-r flex-grid__cell--1of6'>
        <a href={url}>
          <p className='t--sml'>
            <strong>
              {eventTime(start, end, currentDay)}
            </strong>
          </p>

          <div className='c-schedule__assignees u-mrgn-top--x2'>
            {
              users.map(
                user => <UserInitials key={user.id} {...user} />
              )
            }
          </div>
        </a>
      </div>

      <div className='flex-grid__cell u-mrgn-l--x0'>
        <a href={url}>
          <h5 className='c-schedule__title'>
            <strong>
              <span className={`c-event__type c--${selectedColor}`}>
                <span className='c-schedule__title-contact'>
                  {contact && (
                    contact.display_name + ' ' + String.fromCharCode(183) + ' '
                  )}
                </span>
                {EventTypes[type].pretty}
              </span>
              {generateTitle(title)}
            </strong>
            <span className='c-schedule__title-assignees'>
              {
                users.map(
                  user => <UserInitials key={user.id} {...user} />
                )
              }
            </span>
          </h5>

          {location !== '' && (
            <p className='c-schedule__location t--sml'>
              {location}
            </p>
          )}

          <div className='c-schedule__for t--sml c--dark-metal'>
            {
              job && (
                <>
                  {'For '}
                  {
                    contact &&
                    (
                      contact.display_name
                    )
                  }
                  {generateTitle(job.title)}
                </>
              )
            }
          </div>
          {
            contact &&
            (
              <div className='c-schedule__for t--sml c--dark-metal u-show-print-only'>
                {
                  `Mobile: ${contact.mobile_number}`
                }
              </div>
            )
          }
        </a>
      </div>

      <div className='c-event__actions u-no-print'>
        <EventActions
          eventId={id}
          jobId={jobId}
          type={type}
          onDelete={onDelete}
          onColorChange={onColorChange}
          canDelete={canManageEvent}
          selectedColor={selectedColor}
          colors={[defaultColor, ...eventColors]}
        />
      </div>

    </div>
  </li>
)

Event.defaultProps = {
  title: '',
  location: '',
  locationUrl: '',
  users: [],
  canViewContact: false,
  contact: null,
  job: null,
  jobId: null,
  onDelete: () => {},
  onColorChange: () => {},
  canManageEvent: false,
  eventColors: []
}

Event.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    GENERAL_EVENT,
    APPOINTMENT,
    WORK_DATE,
    GROUPED_AUTOMATION_TASKS
  ]).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  location: PropTypes.string,
  locationUrl: PropTypes.string,
  selectedColor: PropTypes.string.isRequired,
  defaultColor: PropTypes.string.isRequired,
  users: PropTypes.array,
  currentDay: PropTypes.string.isRequired,
  canViewContact: PropTypes.bool,
  contact: PropTypes.shape({
    display_name: PropTypes.string
  }),
  job: PropTypes.shape({
    title: PropTypes.string
  }),
  jobId: PropTypes.number,
  onDelete: PropTypes.func,
  onColorChange: PropTypes.func,
  canManageEvent: PropTypes.bool,
  eventColors: PropTypes.arrayOf(PropTypes.string)
}

export default Event
